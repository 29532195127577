/* eslint-disable react/no-danger */
import React from 'react';
import getLegacyPageName from './getLegacyPageName';

const buildEnvs = require('../../../_envs.json');

const activeEnv =
    process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'local';

const useTags = (pathname) =>
{
    const adobeSrc = buildEnvs[ activeEnv ].ADOBE_SRC;
    const optimizelySrc = buildEnvs[ activeEnv ].OPTIMIZELY_SRC;
    const headComponents = [];
    let sendPageView = null;

    const legacyPageName = getLegacyPageName( pathname );

    if ( optimizelySrc)
    {
        headComponents.push(<script src={ optimizelySrc } />);
    }

    if ( adobeSrc )
    {
        headComponents.push(
            <script src={ adobeSrc } async />
        );
        headComponents.push(
            <script 
                dangerouslySetInnerHTML={{__html: `
                    function qlAdobePageView( pageName ) {
                        var adobeDataLayer = window.adobeDataLayer = window.adobeDataLayer || [];
                        adobeDataLayer.push({
                            "event": "PAGEVIEW",
                            "metadata": {
                                "page_name": pageName
                            }
                        })
                    }
                `}}
            />
        );
        sendPageView = async () => 
        {
            if ( typeof window !== 'undefined' && window.qlAdobePageView ) window.qlAdobePageView( legacyPageName )
        }
    }
    return {headComponents, sendPageView}
}

export default useTags;
